import React from "react";
import { SvgIcon } from "../../common/SvgIcon";
import "./AppsStyle.scss";

interface Project {
  name: string;
  description: string;
  codeLink?: string;
  liveLink?: string;
  activeDev?: boolean;
}

const projects: Project[] = [
  {
    name: "Step Sequencer",
    description:
      "Musical application that sequences audio tones. Uses Tone.js, React, and Typescript.",
    activeDev: true,
    codeLink: "https://github.com/rogermawer/step-sequencer",
    liveLink: "https://main.d2ccmx7twxyjn2.amplifyapp.com/",
  },
];

export const Apps: React.FC = () => (
  <div className="apps">
    {projects.map((project) => (
      <div className="project">
        <h3>
          {project.name}

          {project.activeDev ? (
            <span className="green-text">{` (active development)`}</span>
          ) : null}
        </h3>
        <p className="description">{project.description}</p>

        {project.codeLink ? (
          <a target={"_blank"} href={project.codeLink} rel="noreferrer">
            <SvgIcon type="code" />
          </a>
        ) : null}

        {project.liveLink ? (
          <a target={"_blank"} href={project.liveLink} rel="noreferrer">
            <SvgIcon type="live" />
          </a>
        ) : null}
      </div>
    ))}
  </div>
);
