import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Apps } from "./components/Apps/Apps";
import { Contact } from "./components/Contact/Contact";
import { NavLink } from "react-router-dom";
import { Me } from "./components/Me/Me";

export interface NavRoute {
  name: string;
  path: string;
  container: JSX.Element;
}

export const routes: NavRoute[] = [
  { name: "Home", path: "/", container: <Home /> },
  { name: "Apps", path: "/apps", container: <Apps /> },
  { name: "Me", path: "/me", container: <Me /> },
  { name: "Contact", path: "/contact", container: <Contact /> },
];

export const App: React.FC = () => (
  <div className="app">
    <Routes>
      {routes.map((r) => (
        <Route path={r.path} element={r.container} />
      ))}
    </Routes>

    <nav className="nav-bar">
      {routes.map((r) => (
        <NavLink to={r.path}>{r.name}</NavLink>
      ))}
    </nav>
  </div>
);
