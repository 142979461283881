import React from "react";
import rainbow_trees from "../../img/rainbowtrees.jpg";
import "./HomeStyle.scss";

const name = "Roger Mawer";
const description = "Software Developer // Musician";

export const Home: React.FC = () => (
  <div className="home">
    <h1>{name}</h1>
    <p>{description}</p>
    <div className="main-image">
      <img alt="rainbow trees" src={rainbow_trees} />
    </div>
  </div>
);
