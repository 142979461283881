import React from "react";
import "./ContactStyle.scss";

interface ContactItem {
  name: string;
  link: string;
  text: string;
}

const contacts: ContactItem[] = [
  {
    name: "email",
    link: "mailto:rogermawer@gmail.com?subject=Hi!",
    text: "rogermawer@gmail.com",
  },
  {
    name: "gihub",
    link: "https://github.com/rogermawer",
    text: "github.com/rogermawer",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/roger-mawer-77a3a516b",
    text: "@rogermawer",
  },
];

export const Contact: React.FC = () => (
  <div className="contact">
    {contacts.map((contact) => (
      <div>
        <h1>{contact.name}</h1>
        <a href={contact.link}>{contact.text}</a>
      </div>
    ))}
  </div>
);
