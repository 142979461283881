import { FunctionComponent } from "react";
import "./SvgIconStyle.scss";

interface SvgIconProps {
  type: string;
  className?: string;
  onClick?: () => void;
}

interface SvgIcons {
  [icon: string]: { path: string[]; viewBox: string };
}

export const SvgIcon: FunctionComponent<SvgIconProps> = ({
  type,
  className,
  onClick,
}) => (
  <svg
    className={`icon${className ? ` ${className}` : ""}`}
    viewBox={icons[type].viewBox}
    onClick={onClick}
  >
    {icons[type].path.map((p, i) => (
      <path key={i} d={p} />
    ))}
  </svg>
);

const icons: SvgIcons = {
  code: {
    path: [
      "M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z",
    ],
    viewBox: "0 0 640 512",
  },
  live: {
    path: [
      "M24.104,41.577c-0.025,0-0.053-0.001-0.078-0.001c-1.093-0.035-2.025-0.802-2.271-1.867l-5.46-23.659l-3.199,8.316c-0.357,0.93-1.252,1.544-2.249,1.544H2.41c-1.331,0-2.41-1.079-2.41-2.41c0-1.331,1.079-2.41,2.41-2.41h6.78l5.433-14.122c0.38-0.989,1.351-1.612,2.418-1.54c1.057,0.074,1.941,0.831,2.18,1.863l5.186,22.474l4.618-15.394c0.276-0.923,1.078-1.592,2.035-1.702c0.953-0.107,1.889,0.36,2.365,1.198l4.127,7.222h7.037c1.331,0,2.41,1.079,2.41,2.41c0,1.331-1.079,2.41-2.41,2.41h-8.436c-0.865,0-1.666-0.463-2.094-1.214l-2.033-3.559l-5.616,18.722C26.104,40.88,25.164,41.577,24.104,41.577z",
    ],
    viewBox: "0 0 47 47",
  },
};
