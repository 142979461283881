import React from "react";
import "./MeStyle.scss";

type Technologies = string[];

const technologies: Technologies = [
  "Frontend development",
  "Backend development",
  "App development",
  "SPAs & Custom websites",
  "Wordpress",
];

const languages: Technologies = [
  "React",
  "Typescript",
  "Javascript",
  "Java",
  "Python",
  "Vue",
  "Postgres",
  "HTTP",
  "Marketing/SEO",
];

export const Me: React.FC = () => (
  <div className="me">
    <h3>What I do:</h3>
    {technologies.map((technology) => (
      <div>{technology}</div>
    ))}

    <h3>Familiar technologies:</h3>
    {languages.map((language) => (
      <div>{language}</div>
    ))}
  </div>
);
